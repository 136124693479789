/**
 * @fileoverview gRPC-Web generated client stub for influencer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.4
// source: influencer_account_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var influencer_profile_pb = require('./influencer_profile_pb.js')
const proto = {};
proto.influencer = require('./influencer_account_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.influencer.InfluencerAccountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.influencer.InfluencerAccountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.SignupRequest,
 *   !proto.influencer.SignupResponse>}
 */
const methodDescriptor_InfluencerAccountService_Signup = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/Signup',
  grpc.web.MethodType.UNARY,
  proto.influencer.SignupRequest,
  proto.influencer.SignupResponse,
  /**
   * @param {!proto.influencer.SignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.SignupResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.SignupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.SignupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.signup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/Signup',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_Signup,
      callback);
};


/**
 * @param {!proto.influencer.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.SignupResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.signup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/Signup',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_Signup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.UpdateProfileRequest,
 *   !proto.influencer.UpdateProfileRequestResponse>}
 */
const methodDescriptor_InfluencerAccountService_UpdateProfile = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/UpdateProfile',
  grpc.web.MethodType.UNARY,
  proto.influencer.UpdateProfileRequest,
  proto.influencer.UpdateProfileRequestResponse,
  /**
   * @param {!proto.influencer.UpdateProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.UpdateProfileRequestResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.UpdateProfileRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.UpdateProfileRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_UpdateProfile,
      callback);
};


/**
 * @param {!proto.influencer.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.UpdateProfileRequestResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_UpdateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.GetProfileRequest,
 *   !proto.influencer.GetProfileResponse>}
 */
const methodDescriptor_InfluencerAccountService_GetProfile = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/GetProfile',
  grpc.web.MethodType.UNARY,
  proto.influencer.GetProfileRequest,
  proto.influencer.GetProfileResponse,
  /**
   * @param {!proto.influencer.GetProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.GetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.GetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.GetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.getProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/GetProfile',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_GetProfile,
      callback);
};


/**
 * @param {!proto.influencer.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.GetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.getProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/GetProfile',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_GetProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.SetProfileLiveRequest,
 *   !proto.influencer.SetProfileLiveResponse>}
 */
const methodDescriptor_InfluencerAccountService_SetProfileLive = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/SetProfileLive',
  grpc.web.MethodType.UNARY,
  proto.influencer.SetProfileLiveRequest,
  proto.influencer.SetProfileLiveResponse,
  /**
   * @param {!proto.influencer.SetProfileLiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.SetProfileLiveResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.SetProfileLiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.SetProfileLiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.SetProfileLiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.setProfileLive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/SetProfileLive',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_SetProfileLive,
      callback);
};


/**
 * @param {!proto.influencer.SetProfileLiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.SetProfileLiveResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.setProfileLive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/SetProfileLive',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_SetProfileLive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.GetProfileImageUploadUrlRequest,
 *   !proto.influencer.GetProfileImageUploadUrlResponse>}
 */
const methodDescriptor_InfluencerAccountService_GetProfileImageUploadUrl = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/GetProfileImageUploadUrl',
  grpc.web.MethodType.UNARY,
  proto.influencer.GetProfileImageUploadUrlRequest,
  proto.influencer.GetProfileImageUploadUrlResponse,
  /**
   * @param {!proto.influencer.GetProfileImageUploadUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.GetProfileImageUploadUrlResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.GetProfileImageUploadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.GetProfileImageUploadUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.GetProfileImageUploadUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.getProfileImageUploadUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/GetProfileImageUploadUrl',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_GetProfileImageUploadUrl,
      callback);
};


/**
 * @param {!proto.influencer.GetProfileImageUploadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.GetProfileImageUploadUrlResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.getProfileImageUploadUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/GetProfileImageUploadUrl',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_GetProfileImageUploadUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.ImageUploadSuccessRequest,
 *   !proto.influencer.ImageUploadSuccessResponse>}
 */
const methodDescriptor_InfluencerAccountService_SucceedInImageUpload = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountService/SucceedInImageUpload',
  grpc.web.MethodType.UNARY,
  proto.influencer.ImageUploadSuccessRequest,
  proto.influencer.ImageUploadSuccessResponse,
  /**
   * @param {!proto.influencer.ImageUploadSuccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.ImageUploadSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.ImageUploadSuccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.ImageUploadSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.ImageUploadSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountServiceClient.prototype.succeedInImageUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountService/SucceedInImageUpload',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_SucceedInImageUpload,
      callback);
};


/**
 * @param {!proto.influencer.ImageUploadSuccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.ImageUploadSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountServicePromiseClient.prototype.succeedInImageUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountService/SucceedInImageUpload',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountService_SucceedInImageUpload);
};


module.exports = proto.influencer;


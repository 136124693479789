/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_mobile_analytics_app_id": "1f9256f75c6846b4bcc0c0cdc52295ad",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-1:a9553256-1221-44e8-8e9e-7ed248965fbe",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_kSahivpup",
    "aws_user_pools_web_client_id": "9hfqrqke1n3c4ogrij735lf5i",
    "oauth": {
        "domain": "jbh5d693rhma-staging.auth.us-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://admin.trufflear.com/auth,http://localhost:3000/auth",
        "redirectSignOut": "https://admin.trufflear.com/auth,http://localhost:3000/auth",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 7,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

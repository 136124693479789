import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import i18n from './i18n'
import authLocalizations from './locate/auth-localizations.json'
import { I18n } from 'aws-amplify';
import AmplifyI18n from "amplify-i18n"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBY11Q_KMIjfgq9BJyjCWffu3jMhKou_go",
  authDomain: "trufflear-df86e.firebaseapp.com",
  projectId: "trufflear-df86e",
  storageBucket: "trufflear-df86e.appspot.com",
  messagingSenderId: "456992958053",
  appId: "1:456992958053:web:6403661fe1544d9edc488c",
  measurementId: "G-MTS33BW9VV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const locales = ["en", "zh-Hant", "zh-Hans"]
AmplifyI18n.configure(locales);
I18n.putVocabularies(authLocalizations);

const currentLang = i18n.language

if (currentLang === 'zh-TW') {
    I18n.setLanguage("zh-Hant")
} else if (currentLang === 'zh-CH') {
    I18n.setLanguage("zh-Hans")
}

awsconfig.oauth.redirectSignIn = `${window.location.origin}/auth`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/auth`;

Amplify.configure(awsconfig);


ReactDOM.render(
  <App analytics={analytics} />
  ,document.getElementById('root')
);

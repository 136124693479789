import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    header:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        paddingBottom: 0
    },
    input: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    loginText: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    displayLogo: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px'
    },
    logoTopText: {
        marginBottom: '1px', 
        marginTop: '0',
        fontFamily: 'Inter',
        fontWeight: '700',
        color: '#282828',
        fontSize: '20px',
        lineHeight: '24px'
    }
}));

export default function HomePage({ analytics }){
    //Initial Config
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNextButtonClicked = () => {
        logEvent(analytics, 'continue_button_clicked');
        if (window.location.host.includes('localhost') || window.location.host.includes('admin')) {
            navigate('/auth');
        } else {
            window.location.href = "https://admin.trufflear.com/auth";
        }
    }

    useEffect(() => {
        logEvent(analytics, 'home_page_viewed');
    }, []);

    return(
        <>
        <div className='home-panel'>
            <div className='main-container'>              
                    <div style={{display: 'flex'}} className='logo-box '>
                        <div className={classes.displayLogo}>
                            <img src="/img/admin-logo.svg" />
                        </div>
                        <div className={classes.loginText}>
                            <p className='login-top-text'>TRUFFLEAR</p>
                            <p className='login-bottom-text'>Search engine for your content</p>
                        </div>
                    </div>
                    <div className='intro'>
                        <b>Search Made Easier</b><br></br><br></br>
                        With hundreds or thousands of your creative posts on <b>Instagram</b>,
                        your followers can now search through them with Trufflear! 🥐<br></br>
                    </div>
                    <div className='btn-container'>
                        <img src='/img/btn-play.svg' 
                            onClick={handleNextButtonClicked} />
                    </div>   
                    
                    <p className='copyright'> { t('copyright') } </p>
                    <p className='policy' onClick={()=>{navigate("/privacy-policy", { replace: true });}}> { t('privacyPolicy') } </p>
            </div>
        </div>
        </>
    )
}
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { AuthPage, HomePage, OnboardingPage, SearchPage, ProfilePage, SettingsPage, PolicyPage } from '../views';

export const AppRouter = ({ analytics }) => {
    var routes;
    if (window.location.host.includes('localhost')) {
        routes = (
            <Routes>
                <Route path="/auth" element={<AuthPage analytics={analytics}/>} />        
                <Route path="/onboarding" element={<OnboardingPage analytics={analytics}/>} />    
                <Route path="/profile" element={<ProfilePage analytics={analytics}/>} />
                <Route path="/settings" element={<SettingsPage analytics={analytics}/>} />
                <Route path="/privacy-policy" element={<PolicyPage analytics={analytics}/>} />
                <Route path="/:username" element={<SearchPage analytics={analytics}/>} />
                <Route path="/" element={<HomePage analytics={analytics}/>} />
            </Routes>
        )
    } else if (window.location.host.includes('admin')) {
        routes = (
            <Routes>
                <Route path="/auth" element={<AuthPage analytics={analytics}/>} />        
                <Route path="/onboarding" element={<OnboardingPage analytics={analytics}/>} />    
                <Route path="/profile" element={<ProfilePage analytics={analytics}/>} />
                <Route path="/settings" element={<SettingsPage analytics={analytics}/>} />
                <Route path="/" element={<HomePage analytics={analytics}/>} />
            </Routes>
        )
    } else {
        routes = (
            <Routes>
                <Route path="/" element={<HomePage analytics={analytics}/>} />
                <Route path="/privacy-policy" element={<PolicyPage analytics={analytics}/>} />
                <Route path="/:username" element={<SearchPage analytics={analytics}/>} />
            </Routes>
        )
    }

    return (
        <Router>
            { routes }
        </Router> 
    )
}
    
        

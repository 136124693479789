import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import './index.scss'
import { useTranslation } from 'react-i18next';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {
    InstantSearch,
    SearchBox,
    Snippet,
    useInfiniteHits,
    useInstantSearch
} from "react-instantsearch-hooks-web";
import InfiniteScroll from 'react-infinite-scroll-component';
import { logEvent } from "firebase/analytics";
import debounce from "lodash.debounce";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: "ChSimQ91DO7xc7ahgEvZAGXz6rftJq3A",
        nodes: [
        {
            host: "find.trufflear.com",
            port: "8108",
            protocol: "https",
        },
        ],
    },
    additionalSearchParameters: {
        queryBy: "hashtags,mentions,caption",
        sortBy: "created_at_time_millis:desc",
        highlight_affix_num_tokens: 2,
        per_page: '60'
    },
});

const { InfluencerPublicProfileServiceClient } = require('../../network/services/influencer_public_profile_service_grpc_web_pb');

const { GetInfluencerPublicProfileRequest } = require('../../network/services/influencer_public_profile_service_pb');

const client = new InfluencerPublicProfileServiceClient('https://service.trufflear.com:8080/', null, null);


export default function SearchPage({ analytics }) {
    //Initial Config
    const { username } = useParams(); 
    const { t } = useTranslation();

    // States 
    const [errorView, setErrorView] = useState({
        shouldShowError: false,
        errorMessage: ''
    });
    const [bio, setBio] = useState('');
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('')
    const [avatar, setAvatar] = useState('/img/default_profile.png');
    const [shouldShowUserInfo, setShouldShowUserInfo] = useState(true);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const numHitsReturned = useRef(0);
    const _currentPageHits = useRef([]);
    const _currentQuery = useRef('');

    const handleTooltipCancelled = () => {
        setTooltipIsOpen(false);
    }

    useEffect(() => {
        logEvent(analytics, 'search_page_viewed', {
            'username': username
        });

        document.title = `${username} | ${t('trufflear')}`;

        if (navigator.userAgent.indexOf("Instagram") !== -1) {
            if(window.navigator.userAgent.match(/Android/i) || window.navigator.userAgent.match(/iphone/i)) {
                logEvent(analytics, 'tool_tip_displayed', {
                    'username': username
                });
                setTooltipIsOpen(true);
                setTimeout( ()=>{ setTooltipIsOpen(false) }, 3500 );
            }
        }

        let request = new GetInfluencerPublicProfileRequest();
        request.setUsername(username);
        client.getInfluencerPublicProfile(request, {}, (err, response) => {
            if (err) {
                console.error(err);
                setErrorView({shouldShowError: true, errorMessage: t('profile_not_found_error_view_text')});
            } else if(response.getError()) {
              const errorType = response.getError().getErrorType()
              if (errorType === 2) {
                setErrorView({shouldShowError: true, errorMessage: t('profile_not_live_error_view_text')});
              } else {
                setErrorView({shouldShowError: true, errorMessage: t('profile_not_found_error_view_text')});
              }
            } else if(response.getSuccess()) {
                let profile = response.getSuccess().getProfile()
              
                setBio(profile.getBioDescription())
                setCategory(profile.getCategoryTitle())
                setUser(profile.getProfileTitle())
                setEmail(profile.getEmail())
                const profilePicUrl = profile.getProfilePicUrl();
                if(profilePicUrl && profilePicUrl !== '') {
                    setAvatar(profile.getProfilePicUrl())
                } else {
                    setAvatar('/img/default_profile.png')
                }
            }
          });
    }, [analytics, t, username])

    const handlePostClicked = (permalink, postId) => {
        logEvent(analytics, "post_clicked", {
            "username": username,
            "search_term": _currentQuery.current,
            "post_id": postId,
            "permalink": permalink
        });
        window.location.href = permalink;
    }

    const Hit = ({ hit }) => (
        <div className='search-item cursor-pointer' 
            onClick={()=> { handlePostClicked(hit.permalink, hit.id) }}>
            <img className='item-img' alt={hit.caption} src={hit.thumbnail_url}></img>
            <div className='item-info'>
                <Snippet attribute="caption" hit={hit}/>
            </div>
        </div>
      );

    function CustomInfiniteHits() {
        const {
            hits,
            currentPageHits,
            isLastPage,
            showMore,
        } = useInfiniteHits();

        numHitsReturned.current = hits.length;
        _currentPageHits.current = currentPageHits;

        return <>{
            <InfiniteScroll dataLength={hits.length} next={showMore} hasMore={!isLastPage}>
                <div className="search-list" >
                    { hits.map(hit => <Hit key={hit.objectID} hit={hit} />) }
                </div>
            </InfiniteScroll>
        }</>;
    } 

    function Middleware() {
        const { use } = useInstantSearch();
    
        useLayoutEffect(() => {
          return use(AnalyticsMiddleware);
        }, [use]);
      
        return null;
    
        function AnalyticsMiddleware() {
            const sendEventDebounced = debounce((uiState) => {
                const query = uiState[""].query;
                _currentQuery.current = query ? query : '';
                if (query) {
                    logEvent(analytics, "query", {
                        "username": username,
                        "search_term": query,
                        "num_result": numHitsReturned
                    });

                    _currentPageHits.current.forEach((hit) => {
                        logEvent(analytics, "post_returned", {
                            "username": username,
                            "search_term": query,
                            "post_id": hit.id,
                            "permalink": hit.permalink
                        });
                    });
                }
            }, 1500);

            const hideUserInfo = (uiState) => {
                const query = uiState[""].query;
                if (query) {
                    setShouldShowUserInfo(false);
                } else {
                    //setShouldShowUserInfo(true);
                }
            }
          
            return {
              onStateChange({ uiState }) {
                sendEventDebounced(uiState);
                hideUserInfo(uiState);
              },
              subscribe() {},
              unsubscribe() {},
            };
        }
    }

    return(
        <>
            { !errorView.shouldShowError ? (
                <div>
                    {tooltipIsOpen == true && (
                        <div className='tooltip'>
                            <div className='tooltip-close-box'>
                                <button className='tooltip-close' onClick={handleTooltipCancelled}>
                                    <p>x</p>
                                </button>
                            </div>
                            <p className='tooltip-content'>{t('instagram_browser_tooltip_text')}</p>
                        </div>
                    )}
                    <InstantSearch
                        indexName={email}
                        searchClient={ typesenseInstantsearchAdapter.searchClient }
                    >
                        <Middleware />
                        <div className='search-container'>
                            <div className='main-container'>
                                <div className='searchbox'>
                                    <img src='/img/search.svg'></img>
                                    <SearchBox 
                                        id='search-input' 
                                        className='searchbox-input'
                                        placeholder={t('searchPlaceholder')} />
                                </div>
                            
                                <div className='search-result no-margin'>
                                    {shouldShowUserInfo && (
                                        <div className='restaurant-section'>
                                            <div className='restaurant-avatar'>
                                                <img src={ avatar } />
                                            </div>
                                            <div className='restaurant-info'>
                                                <p className='info-one'> { user } </p>
                                                <p className='info-two'> { category } </p>
                                                <p className='info-three'> { bio } </p>
                                            </div>
                                        </div>
                                    )}
                                    <CustomInfiniteHits />
                                </div>
                            </div>
                        </div>
                    </InstantSearch>
                </div>
            ) : (
                <div className='error-container'>
                    <div className='error-box'>
                        <div>
                            <div className='img-cover'>
                                <img className='error-img' src='/img/login-logo.png'></img>
                            </div>
                            <p className='error-text'> { errorView.errorMessage }</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

/**
 * @fileoverview gRPC-Web generated client stub for influencer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.4
// source: influencer_account_connect_ig_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.influencer = require('./influencer_account_connect_ig_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.influencer.InfluencerAccountConnectIgServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.influencer.InfluencerAccountConnectIgServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.GetIgAuthorizationWindowUrlRequest,
 *   !proto.influencer.GetIgAuthorizationWindowUrlResponse>}
 */
const methodDescriptor_InfluencerAccountConnectIgService_GetIgAuthorizationWindowUrl = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountConnectIgService/GetIgAuthorizationWindowUrl',
  grpc.web.MethodType.UNARY,
  proto.influencer.GetIgAuthorizationWindowUrlRequest,
  proto.influencer.GetIgAuthorizationWindowUrlResponse,
  /**
   * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.GetIgAuthorizationWindowUrlResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.GetIgAuthorizationWindowUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.GetIgAuthorizationWindowUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountConnectIgServiceClient.prototype.getIgAuthorizationWindowUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/GetIgAuthorizationWindowUrl',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_GetIgAuthorizationWindowUrl,
      callback);
};


/**
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.GetIgAuthorizationWindowUrlResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountConnectIgServicePromiseClient.prototype.getIgAuthorizationWindowUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/GetIgAuthorizationWindowUrl',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_GetIgAuthorizationWindowUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.ConnectIgUserMediaRequest,
 *   !proto.influencer.ConnectIgUserMediaResponse>}
 */
const methodDescriptor_InfluencerAccountConnectIgService_ConnectIgUserMedia = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountConnectIgService/ConnectIgUserMedia',
  grpc.web.MethodType.UNARY,
  proto.influencer.ConnectIgUserMediaRequest,
  proto.influencer.ConnectIgUserMediaResponse,
  /**
   * @param {!proto.influencer.ConnectIgUserMediaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.ConnectIgUserMediaResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.ConnectIgUserMediaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.ConnectIgUserMediaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.ConnectIgUserMediaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountConnectIgServiceClient.prototype.connectIgUserMedia =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/ConnectIgUserMedia',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_ConnectIgUserMedia,
      callback);
};


/**
 * @param {!proto.influencer.ConnectIgUserMediaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.ConnectIgUserMediaResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountConnectIgServicePromiseClient.prototype.connectIgUserMedia =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/ConnectIgUserMedia',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_ConnectIgUserMedia);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.influencer.RefreshIgUserMediaRequest,
 *   !proto.influencer.RefreshIgUserMediaResponse>}
 */
const methodDescriptor_InfluencerAccountConnectIgService_RefreshIgUserMedia = new grpc.web.MethodDescriptor(
  '/influencer.InfluencerAccountConnectIgService/RefreshIgUserMedia',
  grpc.web.MethodType.UNARY,
  proto.influencer.RefreshIgUserMediaRequest,
  proto.influencer.RefreshIgUserMediaResponse,
  /**
   * @param {!proto.influencer.RefreshIgUserMediaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.influencer.RefreshIgUserMediaResponse.deserializeBinary
);


/**
 * @param {!proto.influencer.RefreshIgUserMediaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.influencer.RefreshIgUserMediaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.influencer.RefreshIgUserMediaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.influencer.InfluencerAccountConnectIgServiceClient.prototype.refreshIgUserMedia =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/RefreshIgUserMedia',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_RefreshIgUserMedia,
      callback);
};


/**
 * @param {!proto.influencer.RefreshIgUserMediaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.influencer.RefreshIgUserMediaResponse>}
 *     Promise that resolves to the response
 */
proto.influencer.InfluencerAccountConnectIgServicePromiseClient.prototype.refreshIgUserMedia =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/influencer.InfluencerAccountConnectIgService/RefreshIgUserMedia',
      request,
      metadata || {},
      methodDescriptor_InfluencerAccountConnectIgService_RefreshIgUserMedia);
};


module.exports = proto.influencer;


// source: influencer_account_connect_ig_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.influencer.ConnectIgUserMediaRequest', null, global);
goog.exportSymbol('proto.influencer.ConnectIgUserMediaResponse', null, global);
goog.exportSymbol('proto.influencer.GetIgAuthorizationWindowUrlRequest', null, global);
goog.exportSymbol('proto.influencer.GetIgAuthorizationWindowUrlResponse', null, global);
goog.exportSymbol('proto.influencer.RefreshIgUserMediaRequest', null, global);
goog.exportSymbol('proto.influencer.RefreshIgUserMediaResponse', null, global);
goog.exportSymbol('proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired', null, global);
goog.exportSymbol('proto.influencer.RefreshIgUserMediaResponse.StateCase', null, global);
goog.exportSymbol('proto.influencer.RefreshIgUserMediaResponse.Success', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.GetIgAuthorizationWindowUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.GetIgAuthorizationWindowUrlRequest.displayName = 'proto.influencer.GetIgAuthorizationWindowUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.GetIgAuthorizationWindowUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.GetIgAuthorizationWindowUrlResponse.displayName = 'proto.influencer.GetIgAuthorizationWindowUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.ConnectIgUserMediaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.ConnectIgUserMediaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.ConnectIgUserMediaRequest.displayName = 'proto.influencer.ConnectIgUserMediaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.ConnectIgUserMediaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.ConnectIgUserMediaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.ConnectIgUserMediaResponse.displayName = 'proto.influencer.ConnectIgUserMediaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.RefreshIgUserMediaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.RefreshIgUserMediaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.RefreshIgUserMediaRequest.displayName = 'proto.influencer.RefreshIgUserMediaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.RefreshIgUserMediaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.influencer.RefreshIgUserMediaResponse.oneofGroups_);
};
goog.inherits(proto.influencer.RefreshIgUserMediaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.RefreshIgUserMediaResponse.displayName = 'proto.influencer.RefreshIgUserMediaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.RefreshIgUserMediaResponse.Success = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.RefreshIgUserMediaResponse.Success, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.RefreshIgUserMediaResponse.Success.displayName = 'proto.influencer.RefreshIgUserMediaResponse.Success';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.displayName = 'proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.GetIgAuthorizationWindowUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.GetIgAuthorizationWindowUrlRequest}
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.GetIgAuthorizationWindowUrlRequest;
  return proto.influencer.GetIgAuthorizationWindowUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.GetIgAuthorizationWindowUrlRequest}
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.GetIgAuthorizationWindowUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.GetIgAuthorizationWindowUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.GetIgAuthorizationWindowUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.GetIgAuthorizationWindowUrlResponse}
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.GetIgAuthorizationWindowUrlResponse;
  return proto.influencer.GetIgAuthorizationWindowUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.GetIgAuthorizationWindowUrlResponse}
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.GetIgAuthorizationWindowUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.GetIgAuthorizationWindowUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.influencer.GetIgAuthorizationWindowUrlResponse} returns this
 */
proto.influencer.GetIgAuthorizationWindowUrlResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.ConnectIgUserMediaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.ConnectIgUserMediaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.ConnectIgUserMediaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.ConnectIgUserMediaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    instagramAuthCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.ConnectIgUserMediaRequest}
 */
proto.influencer.ConnectIgUserMediaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.ConnectIgUserMediaRequest;
  return proto.influencer.ConnectIgUserMediaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.ConnectIgUserMediaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.ConnectIgUserMediaRequest}
 */
proto.influencer.ConnectIgUserMediaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagramAuthCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.ConnectIgUserMediaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.ConnectIgUserMediaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.ConnectIgUserMediaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.ConnectIgUserMediaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstagramAuthCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instagram_auth_code = 1;
 * @return {string}
 */
proto.influencer.ConnectIgUserMediaRequest.prototype.getInstagramAuthCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.influencer.ConnectIgUserMediaRequest} returns this
 */
proto.influencer.ConnectIgUserMediaRequest.prototype.setInstagramAuthCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.ConnectIgUserMediaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.ConnectIgUserMediaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.ConnectIgUserMediaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.ConnectIgUserMediaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.ConnectIgUserMediaResponse}
 */
proto.influencer.ConnectIgUserMediaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.ConnectIgUserMediaResponse;
  return proto.influencer.ConnectIgUserMediaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.ConnectIgUserMediaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.ConnectIgUserMediaResponse}
 */
proto.influencer.ConnectIgUserMediaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.ConnectIgUserMediaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.ConnectIgUserMediaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.ConnectIgUserMediaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.ConnectIgUserMediaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.RefreshIgUserMediaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.RefreshIgUserMediaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.RefreshIgUserMediaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.RefreshIgUserMediaRequest}
 */
proto.influencer.RefreshIgUserMediaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.RefreshIgUserMediaRequest;
  return proto.influencer.RefreshIgUserMediaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.RefreshIgUserMediaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.RefreshIgUserMediaRequest}
 */
proto.influencer.RefreshIgUserMediaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.RefreshIgUserMediaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.RefreshIgUserMediaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.RefreshIgUserMediaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.influencer.RefreshIgUserMediaResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.influencer.RefreshIgUserMediaResponse.StateCase = {
  STATE_NOT_SET: 0,
  SUCCESS: 1,
  AUTHORIZATION_REQUIRED: 2
};

/**
 * @return {proto.influencer.RefreshIgUserMediaResponse.StateCase}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.getStateCase = function() {
  return /** @type {proto.influencer.RefreshIgUserMediaResponse.StateCase} */(jspb.Message.computeOneofCase(this, proto.influencer.RefreshIgUserMediaResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.RefreshIgUserMediaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.RefreshIgUserMediaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.influencer.RefreshIgUserMediaResponse.Success.toObject(includeInstance, f),
    authorizationRequired: (f = msg.getAuthorizationRequired()) && proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.RefreshIgUserMediaResponse}
 */
proto.influencer.RefreshIgUserMediaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.RefreshIgUserMediaResponse;
  return proto.influencer.RefreshIgUserMediaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.RefreshIgUserMediaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.RefreshIgUserMediaResponse}
 */
proto.influencer.RefreshIgUserMediaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.influencer.RefreshIgUserMediaResponse.Success;
      reader.readMessage(value,proto.influencer.RefreshIgUserMediaResponse.Success.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired;
      reader.readMessage(value,proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.deserializeBinaryFromReader);
      msg.setAuthorizationRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.RefreshIgUserMediaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.RefreshIgUserMediaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.influencer.RefreshIgUserMediaResponse.Success.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizationRequired();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.RefreshIgUserMediaResponse.Success.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.RefreshIgUserMediaResponse.Success.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.RefreshIgUserMediaResponse.Success} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.Success.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.RefreshIgUserMediaResponse.Success}
 */
proto.influencer.RefreshIgUserMediaResponse.Success.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.RefreshIgUserMediaResponse.Success;
  return proto.influencer.RefreshIgUserMediaResponse.Success.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.RefreshIgUserMediaResponse.Success} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.RefreshIgUserMediaResponse.Success}
 */
proto.influencer.RefreshIgUserMediaResponse.Success.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.RefreshIgUserMediaResponse.Success.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.RefreshIgUserMediaResponse.Success.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.RefreshIgUserMediaResponse.Success} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.Success.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.prototype.toObject = function(opt_includeInstance) {
  return proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.toObject = function(includeInstance, msg) {
  var f, obj = {
    authWindowUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired}
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired;
  return proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired}
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthWindowUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthWindowUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_window_url = 1;
 * @return {string}
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.prototype.getAuthWindowUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired} returns this
 */
proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired.prototype.setAuthWindowUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Success success = 1;
 * @return {?proto.influencer.RefreshIgUserMediaResponse.Success}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.getSuccess = function() {
  return /** @type{?proto.influencer.RefreshIgUserMediaResponse.Success} */ (
    jspb.Message.getWrapperField(this, proto.influencer.RefreshIgUserMediaResponse.Success, 1));
};


/**
 * @param {?proto.influencer.RefreshIgUserMediaResponse.Success|undefined} value
 * @return {!proto.influencer.RefreshIgUserMediaResponse} returns this
*/
proto.influencer.RefreshIgUserMediaResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.influencer.RefreshIgUserMediaResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.influencer.RefreshIgUserMediaResponse} returns this
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AuthorizationRequired authorization_required = 2;
 * @return {?proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.getAuthorizationRequired = function() {
  return /** @type{?proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired} */ (
    jspb.Message.getWrapperField(this, proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired, 2));
};


/**
 * @param {?proto.influencer.RefreshIgUserMediaResponse.AuthorizationRequired|undefined} value
 * @return {!proto.influencer.RefreshIgUserMediaResponse} returns this
*/
proto.influencer.RefreshIgUserMediaResponse.prototype.setAuthorizationRequired = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.influencer.RefreshIgUserMediaResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.influencer.RefreshIgUserMediaResponse} returns this
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.clearAuthorizationRequired = function() {
  return this.setAuthorizationRequired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.influencer.RefreshIgUserMediaResponse.prototype.hasAuthorizationRequired = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.influencer);

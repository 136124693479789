import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { Modal, Snackbar, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { logEvent } from "firebase/analytics";

const { InfluencerAccountServiceClient } = require("../../network/services/influencer_account_service_grpc_web_pb");
const { InfluencerAccountConnectIgServiceClient } = require("../../network/services/influencer_account_connect_ig_service_grpc_web_pb");
  
const { ConnectIgUserMediaRequest, RefreshIgUserMediaRequest } = require("../../network/services/influencer_account_connect_ig_service_pb");
const {
    GetProfileRequest,
    GetProfileImageUploadUrlRequest,
    ImageUploadSuccessRequest,
    UpdateProfileRequest
} = require("../../network/services/influencer_account_service_pb");

const accountIgClient = new InfluencerAccountConnectIgServiceClient("https://service.trufflear.com:8080/");
const accountClient = new InfluencerAccountServiceClient("https://service.trufflear.com:8080/");

export default function ProfilePage({ analytics }) {
    //Initial Config
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    // States
    const email = useRef("");
    const [username, setUsername] = useState("");
    const [profileTitle, setProfileTitle] = useState("");
    const [bio, setBio] = useState("");
    const [pic, setPic] = useState("");
    const [category, setCategory] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formState, setFormState] = useState({
        title: "",
        professionalCategory: "",
        bio: "",
    });
    const [message, setSnackbarMessage] = useState("");
    
    useEffect(() => {
      if (message) {
        setTimeout(() => {
            setSnackbarMessage("");
        }, 3000);
      }
    }, [message]);

    const handleClose = () => {
        setSnackbarMessage("");
    };

    const handleLinkCopied = () => {
        logEvent(analytics, 'link_copy_button_clicked', {
            "email": email.current
        });
        setSnackbarMessage(t("copied"));
        window.navigator.clipboard.writeText(
            `trufflear.com/${username}`
        );
    }

    const handleEditProfileButtonClicked = () => {
        logEvent(analytics, 'edit_profile_button_clicked', {
            "email": email.current
        });
        setIsEditing(true);
    }

    const handleRefresh = (e) => {
        if (!isLoading) {
            logEvent(analytics, 'refresh_button_clicked', {
                "email": email.current
            });

            setLoading(true);
            Auth.currentSession()
                .then( session => {
                    let token = session.getIdToken().getJwtToken();
                    var metadata = { "id-token": token };
                    
                    var request = new RefreshIgUserMediaRequest();
                    accountIgClient.refreshIgUserMedia(request, metadata, (err, response) => {
                        setLoading(false);
                        if (err) {
                            console.error(err);
                            setSnackbarMessage(t('generic_error_text'));
                        } else {
                            const authRequired = response.getAuthorizationRequired();
                            if (authRequired) {
                                window.location.href = authRequired.getAuthWindowUrl();
                            } else if (response.getSuccess()) {
                                setSnackbarMessage(t('refresh_success_text'));
                            } 
                        }
                    });
                })
                .catch( err => {
                    console.error(err)
                    setLoading(false);
                });   
        }
    };

    const uploadPhoto = async (e) => {

        logEvent(analytics, 'upload_photo_button_clicked', {
            "email": email.current
        });

        const file = e.target.files[0];
        try {
            const sesssion = await Auth.currentSession();
            const token = sesssion.getIdToken().getJwtToken();
            const metadata = { "id-token": token };
            const getImgUrlRequest = new GetProfileImageUploadUrlRequest();
            accountClient.getProfileImageUploadUrl(getImgUrlRequest, metadata, async (err, response) => {
                if (err) {
                    console.error("error", err);
                    setSnackbarMessage(t('generic_error_text'));
                    return;
                }
                const uploadImageUrl = response.getUrl();

                await axios.put(uploadImageUrl, file, {
                    headers: {
                      "Content-Type": file.type
                    },
                });

                const request = new ImageUploadSuccessRequest();
                accountClient.succeedInImageUpload(request, metadata, (err, response) => {
                    if (err) {
                        console.error(err);
                        setSnackbarMessage(t('generic_error_text'));
                    } else {
                        setSnackbarMessage(t('upload_success_text'));
                        fetchData(false, token);
                    }
                });
            });

        } catch (err) {
            setSnackbarMessage(t('generic_error_text'));
            console.error(err);
        }
    };

    async function fetchData(shouldParseCode, token) {
        if (shouldParseCode) {
            parseInstagramCodeAndConnect(token)
        }

        var metadata = { "id-token": token };
        let request = new GetProfileRequest();
    
        accountClient.getProfile(request, metadata, (err, response) => {
            if (err) {
                console.error("error", err);
                return;
            }
            setAccountInfo(response.getInfluencerProfile());
            
        });
    }

    const setAccountInfo = (profile) => {
        setUsername(profile.getUsername());
        let category = profile.getCategoryTitle()
        if (category === '') {
            setCategory(t('profession_category_placeholder'));
        } else {
            setCategory(category);
        }
        let title = profile.getProfileTitle();
        if (title === '') {
            setProfileTitle(t('profile_title_placeholder'));
        } else {
            setProfileTitle(title);
        }
        let bio = profile.getBioDescription()
        if (bio === '') {
            setBio(t('bio_placeholder'))
        } else {
            setBio(bio);
        }
        let pic = profile.getProfilePicUrl()
        if (pic === '') {
            setPic('')
        } else {
            setPic(pic);
        }
        setFormState({
            'bio': bio === '' ? '' : bio,
            'professionalCategory': category === '' ? '' : category,
            'title': title === '' ? '' : title
        });
    }

    const parseInstagramCodeAndConnect = (token) => {
        const error = searchParams.get("error");
        const authCode = searchParams.get("code");
        if (error === 'access_denied') {
            setSnackbarMessage(t('ig_connection_error_text'));
        } else if (authCode) {
            setLoading(true);
            var request = new ConnectIgUserMediaRequest();
            request.setInstagramAuthCode(authCode);
            var metadata = { "id-token": token };
            
            accountIgClient.connectIgUserMedia(request, metadata, (err, response) => {
                setLoading(false);
                if (err) {
                    console.error("connectIgUserMedia", err);
                    
                    setSnackbarMessage(t('ig_permissions_error_text'));
                } 
                if (response) {
                    setSnackbarMessage(t('connect_success_text'));
                    fetchData(false, token);
                }
            });
        }
    }

    useEffect(() => {
        const onStart = async () => {
            let token;
            try {
                let user = await Auth.currentAuthenticatedUser();
                email.current = user.attributes.email;

                logEvent(analytics, 'account_page_viewed', {
                    "email": user.attributes.email 
                });

                token = user.signInUserSession.getIdToken().getJwtToken();
            } catch (err) {
                console.error(err);
                navigate("/", { replace: true });
            }
            fetchData(true, token);
        };

        onStart();
      }, [searchParams, t, navigate, analytics]);

    const formData = [
    {
        name: "title",
        text: t('profile_title_placeholder'),
        required: true,
        maxLength: 30,
        minLength: 2,
        className: "text-inputField",
        value: formState.title
    },
    {
        name: "professionalCategory",
        text: t('profession_category_placeholder'),
        required: false,
        maxLength: 30,
        minLength: 3,
        className: "text-inputField",
        value: formState.professionalCategory
    },
    {
        name: "bio",
        text: t('bio_placeholder'),
        required: false,
        maxLength: 80,
        minLength: 1,
        className: "text-inputField bio-inputFiled",
        value: formState.bio
    },
  ];

  const formSubmitted = async (e) => {
        e.preventDefault();

        logEvent(analytics, 'edit_profile_save_button_clicked', {
            "email": email.current
        });

        var request = new UpdateProfileRequest();
        if (formState.title) request.setProfileTitle(formState.title);
        if (formState.professionalCategory) request.setProfessionCategory(formState.professionalCategory);
        if (formState.bio) request.setBioDescription(formState.bio)

        let curretSession = await Auth.currentSession();
        let token = curretSession.getIdToken().getJwtToken();
        var metadata = {'id-token': token};

        accountClient.updateProfile(request, metadata, (err, response) => {
            if (err) {
                setSnackbarMessage(t('generic_error_text'));
            } else {
                setProfileTitle(formState.title);
                if (formState.professionalCategory !== '') {
                    setCategory(formState.professionalCategory);   
                }
                if (formState.bio !== '') {
                    setBio(formState.bio);
                }
                setSnackbarMessage(t("update_success_text"));
            }
            setIsEditing(false);
        });
    };

    const handleFromData = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };
    
    return (
        <>
        <Snackbar
            open={message}
            onClose={handleClose}
            message={message}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                    >
                    <CloseSharp />
                    </IconButton>
                </React.Fragment>
            }
        />
        <Modal
            open={isEditing}
            onClose={() => setIsEditing(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="eddting-profile">
            <form onSubmit={formSubmitted}>
                {formData?.map((formData) => {
                const {
                    text,
                    name,
                    required,
                    maxLength,
                    minLength,
                    className,
                    value
                } = formData;
                return (
                    <div key={name}>
                    <p className="input-upper-text">{text}</p>

                    <div className="wrap-input">
                        <input
                            name={name}                            
                            onChange={(e) => handleFromData(e)}
                            required={required}
                            className={className}
                            maxLength={maxLength}
                            minLength={minLength}
                            value={value}
                        />
                    </div>
                    </div>
                );
                })}
                <div>
                <button type="submit" className="save-btn-popup">
                    {t("save")}
                </button>
                </div>
            </form>
            </div>
        </Modal>

        <div className="profile-container">
            <div className="main-container">
            <div className="top-panel">
                <div className="top-box">
                    <div className="header">
                    <div className="header-logo">
                        <img src="/img/admin-logo.svg" />
                    </div>
                    <div className="header-text">
                        <p id="main-caption" className="main-caption">
                        {t("refreshPost")}
                        </p>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            logEvent(analytics, 'account_settings_button_clicked', {
                                "email": email.current
                            });
                            navigate("/settings");
                        }}
                    >
                        <img name="setting" src="/img/setting.svg"></img>
                    </div>
                    </div>
                    <div
                        className={isLoading ? "refresh-ment" : "d-hidden refresh-ment"}
                    >
                    <p>{t("refreshMent")}</p>
                    </div>
                </div>
            </div>
            <div className="sub-container">
                <div className="sub-box">
                    <div className="refresh-container">
                    <div
                        className={
                        isLoading ? "refresh-box color-change" : "refresh-box"
                        }
                        onClick={handleRefresh}
                    >
                        <img
                        src="/img/refresh-spin.svg"
                        className={isLoading ? "img-rotate" : ""}
                        alt=""
                        ></img>
                        {t("refresh")}
                    </div>
                    </div>
                    <div className="domain-container">
                    <div className="domain-box">
                        trufflear.com/{username}
                    <img
                        src="/img/btn-copy.svg"
                        alt=""
                        onClick={handleLinkCopied}
                        ></img>
                    </div>
                    </div>
                    <div className="profile-title">
                    <div className="title-content">
                        <span id="title-content">{t("editProfile")}</span>
                        <img
                            src="/img/btn-edit.svg"
                            alt=""
                            onClick={handleEditProfileButtonClicked}
                        ></img>
                    </div>
                    </div>
                    <div className="user-container">
                    <div className="user-avatar">
                        {pic && (
                            <img src={pic} onClick={() => {
                                document.getElementById("fileInput").click();
                            }}></img>
                        )}
                        {!pic && (
                            <p
                                onClick={() => {
                                    document.getElementById("fileInput").click();
                                }}
                            >
                                {t("addPhoto")}
                            </p> 
                        )}
                        <input
                            id="fileInput"
                            onChange={uploadPhoto}
                            type="file"
                            accept="image/jpeg"
                            style={{display: "none"}}
                        ></input>
                    </div>
                    <div className="user-info">
                        <p className="user-name">{profileTitle}</p>
                        <p className="user-influencer">{category}</p>
                        <p className="user-about">{bio}</p>
                    </div>
                    </div>
                    <div className="copyright-container">
                    <p className="copyright">{t('copyright')}</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    );
    }

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Snackbar, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import './index.scss'
import { logEvent } from "firebase/analytics";

const { GetProfileRequest, SetProfileLiveRequest } = require("../../network/services/influencer_account_service_pb");

const { InfluencerAccountServiceClient } = require("../../network/services/influencer_account_service_grpc_web_pb");
const accountClient = new InfluencerAccountServiceClient("https://service.trufflear.com:8080/");

export default function SettingsPage({ analytics }){
    //Initial Config
    const navigate = useNavigate();
    const { t } = useTranslation();

    const email = useRef("");
    const [open, setOpen] = useState(false);
    const [isProfileLive, setProfileLive] = useState(true);
    const [snackBarMessage, setSnackbarMessage] = useState("");

    useEffect(() => {
        if (snackBarMessage) {
          setTimeout(() => {
              setSnackbarMessage("");
          }, 3000);
        }
      }, [snackBarMessage]);

    const handleSignoutButtonClicked= () => {
        logEvent(analytics, 'settings_signout_button_clicked', {
            "email": email.current
        });
        setOpen(true);
    };

    const handleClose = () => {
        logEvent(analytics, 'settings_signout_dialog_cancelled', {
            "email": email.current
        });
        setOpen(false);
    };

    const handleLiveSwitchChanged = () => {
        logEvent(analytics, 'profile_live_toggle_button_clicked', {
            "email": email.current,
            "to_state": !isProfileLive
        });
        Auth.currentSession()
            .then(session => {
                const token = session.getIdToken().getJwtToken();
                const metadata = { "id-token": token };
                const request = new SetProfileLiveRequest();

                request.setSetProfileLive(!isProfileLive);

                accountClient.setProfileLive(request, metadata, (err, resopnse) => {
                    if (err) {
                        console.error("error", err);
                        setSnackbarMessage(t('generic_error_text'));
                        return;
                    }
                    setProfileLive(!isProfileLive);
                    setSnackbarMessage(t('upload_success_text'));
                });
            })
            .catch(error => {
                navigate("/", {replace: true});
                console.error(error);
            })
    }

    const signOut = async() => {
        logEvent(analytics, 'settings_signout_dialog_confirm_button_clicked', {
            "email": email.current
        });
        await Auth.signOut(); 
        navigate('/')
    }
    
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then( user => {
                email.current = user.attributes.email;

                logEvent(analytics, 'settings_page_viewed', {
                    "email": user.attributes.email
                });

                const token = user.signInUserSession.getIdToken().getJwtToken();
                const metadata = { "id-token": token };

                const request = new GetProfileRequest();
                
                accountClient.getProfile(request, metadata, (err, response) => {
                    if (err) {
                        console.error("error", err);
                        return;
                    }
                    setProfileLive(response.getIsProfileLive());
                    
                });
            })
            .catch(error => {
                navigate("/", {replace: true});
                console.error(error);
            })
    }, []);

    return(
        <>
            <Snackbar
                onClose={handleClose}
                open={snackBarMessage}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={handleClose}
                        >
                        <CloseSharp />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <div className='setting-container'>
                <div className='main-container'>
                    <div className='header'>
                        <div className='header-logo'>
                            <img src="/img/login-logo.svg" />
                        </div>
                        <div className='header-text'>
                            <p id='name-txt' className='main-caption'>{t('setting')}</p>
                        </div>
                    </div>
               
                    <div className='live-setting'>
                        <p id='activeProfile' >{t('activeProfile')}</p>
                        <Switch
                            checked = {isProfileLive}
                            onChange={handleLiveSwitchChanged}
                            name="checkedB"
                            color="primary"
                            size='medium'
                            className='active-toggle'
                            id='active-toggle'
                        />
                    </div>
                    {
                        !isProfileLive &&
                            <div className='inactive-profile-explanation'>
                                <p id='explanation-text' className='explanation-text'>{t('inacitve_profile_explanation_text')} </p>
                            </div>
                        
                    }
                    <div className='any-question'>
                        <p id='link-helper' className='link-helper'>{t('linkHelper')} </p>
                        <p id='link-dest' className='link-dest'>team@trufflear.com</p>
                        <p id='link-dest' className='link-dest'>714-488-3201</p>
                    </div>
                    <div className='copyright-container'>
                        <div className='footer'>
                            <Button id='btn-ok' className='btn-ok'
                                onClick={() => { 
                                    logEvent(analytics, 'settings_done_button_clicked', {
                                        "email": email.current
                                    });
                                    navigate(-1); 
                                }}>
                                    {t('done')}
                            </Button>
                            <Button id='btn-out' className='btn-out' onClick={handleSignoutButtonClicked}>{t('signout')}</Button>
                        </div>
                        <p className='copyright'>{t('copyright')}</p>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('questionSignout')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={signOut} color="primary" autoFocus>
                    {t('confirm')}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
import { Hub, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Snackbar, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './index.scss'
import { useNavigate } from 'react-router-dom'
import { logEvent } from "firebase/analytics";

const {InfluencerAccountServiceClient} = require('../../network/services/influencer_account_service_grpc_web_pb');

const {SignupRequest} = require('../../network/services/influencer_account_service_pb');

var client = new InfluencerAccountServiceClient('https://service.trufflear.com:8080/');

export default function AuthPage({ analytics }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (snackbarMessage) {
      setTimeout(() => {
          setSnackbarMessage("");
      }, 2500);
    }
  }, [snackbarMessage]);

  const handleClose = () => {
      setSnackbarMessage("");
  };

  useEffect(() => {
    const signupUser = () => {
      Auth.currentSession()
        .then( session => {
          let token = session.getIdToken().getJwtToken();
          let request = new SignupRequest()
          var metadata = {'id-token': token};
          client.signup(request, metadata, (err, res)=>{
            if(err) {
              if(err.code === 6) {
                navigate('/profile');
              } else {
                setSnackbarMessage(t('generic_error_text'));
                console.error("error creating user", err);
              }
            }
            if (res) {
              navigate('/onboarding')
            }
          })
        })
        .catch( err => {
          console.error("error getting auth", err);
          setSnackbarMessage(t('generic_error_text'));
        });
    }

    const getUser = () => {
      Auth.currentSession()
        .then( session => {
          navigate('/profile');
        })
    }

    logEvent(analytics, 'auth_page_viewed');

    getUser();

    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          signupUser();
          break;
        default:
          break
      }
    });
  }, []);
  
  return (
    <>
    <Snackbar
        open={snackbarMessage}
        onClose={handleClose}
        message={snackbarMessage}
        action={
            <React.Fragment>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={handleClose}
                >
                <CloseSharp />
                </IconButton>
            </React.Fragment>
        }
    />
    <div className='auth-panel'>
      <div className='main-container'>

          <div className='header'>
              <div className='header-logo'>
                  <img src="/img/admin-logo.svg" />
              </div>
              <div className='header-text'>
                  <p className='main-caption'>Trufflear</p>
              </div>
          </div>
          <Authenticator socialProviders={['facebook']}/>
        </div>
    </div>
    </>
  );
}

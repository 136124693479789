import React, { useEffect, useState, useRef } from "react";
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { CircularProgress, IconButton, Snackbar } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { logEvent } from "firebase/analytics";

const { InfluencerAccountConnectIgServiceClient } = require("../../network/services/influencer_account_connect_ig_service_grpc_web_pb");

const { GetIgAuthorizationWindowUrlRequest } = require("../../network/services/influencer_account_connect_ig_service_pb");

var client = new InfluencerAccountConnectIgServiceClient("https://service.trufflear.com:8080/");

export default function OnboardingPage({ analytics }) {
  //Initial Config
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isConnecting, setIsConnecting] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const email = useRef("");

  useEffect(() => {
    if (snackbarMessage) {
      setTimeout(() => {
        setSnackbarMessage("");
      }, 2500);
    }
  }, [snackbarMessage]);

  const handleConnect = (e) => {
    logEvent(analytics, 'ig_connect_button_clicked', {
        "email": email.current
    });

    setIsConnecting(true);
    Auth.currentSession()
      .then( session => {
          let token = session.getIdToken().getJwtToken();
          var metadata = { "id-token": token };
          var request = new GetIgAuthorizationWindowUrlRequest();
          
          client.getIgAuthorizationWindowUrl(request, metadata, (err, response) => {
            setIsConnecting(false);
              if (err) {
                console.error(err);
                setSnackbarMessage(t('generic_error_text'));
              } else {
                window.location.href = response.getUrl();
              }
          });
      })
      .catch( err => {
        setIsConnecting(false);
        setSnackbarMessage(t('generic_error_text'));
        console.error(err);
      });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
          email.current = user.attributes.email;

          logEvent(analytics, 'onboarding_page_viewed', {
            "email": user.attributes.email
          });
      })
      .catch(error => {
        navigate("/", { replace: true });
        console.error(error);
      })
  }, []);

  const handleClose = () => {
    setSnackbarMessage("");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseSharp />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className="board-container">
        <div className="main-container">
          <div className="header">
            <div className="header-logo">
              <img src="/img/admin-logo.svg" />
            </div>
          </div>
          <p id="name-txt" className="main-caption">
            {t("welcome")}
          </p>
          <p id="sub-caption1" className="sub-caption">
            {t("boardOne")}
          </p>
          <p id="sub-caption2" className="sub-caption">
            {t("boardTwo")}{" "}
          </p>
          <p id="sub-caption3" className="sub-caption">
            {t("boardThree")}{" "}
          </p>
          <p id="sub-caption4" className="sub-caption">
            {t("boardFour")}
          </p>
          <div className="signup-form">
            <Button
              id="btn-connect"
              className="btn-connect"
              style={{ marginBottom: "40px" }}
              onClick={handleConnect}
              disabled={isConnecting}
            >
              {isConnecting ? (
                  <CircularProgress color = "inherit" size={24} />
              ): (
              <>     
              <img
                style={{ marginRight: "10px" }}
                src="/img/icon-instagram.svg"
                alt=""
              ></img>
              {t("connectInsta")}
              </>   
              )}
            </Button>
          </div>
          <div className="copyright-container">
            <p className="copyright">{t('copyright')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

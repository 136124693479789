import React, { useContext, useState } from 'react';

import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { light as ThemeLight } from "@Utils";
import { Dialog } from "@Components/UI";
import { AppRouter } from './routers/AppRouter';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  globalStyle: {
    backgroundColor: theme.palette.background.default,
    transition: "all 0.25s linear",
    minHeight: "100%",
  },
}));

const AppConstantsDefault = {
  setTheme: null,
  currentTheme: "light",
  version: "0.0.1", //Para cada commit, o terceiro número sobe 1,
  //para cada release o segundo número sobe 1
  //Para cada versão oficial lançada, o primeiro número sobe 1
  //Quando o número a esquerda sobe, os demais ficam 0, ex: 0.2.12 -> 0.3.0
  header: {
    currentTitle: "Inicio",
  },
  user: {
    name: "",
  },
};

export const AppContext = React.createContext(AppConstantsDefault);

function AppContainer({analytics}) {
  const [dialog, setDialog] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <AppRouter analytics={analytics}/>     
      <Dialog open={dialog} setOpen={setDialog} />
    </>
  );
}

export default function App({analytics}) {
  const appConstants = useContext(AppContext);

  return (
    <ThemeProvider theme={ThemeLight}>
      <AppContext.Provider value={appConstants}>
        <AppContainer analytics={analytics}/>
      </AppContext.Provider>
    </ThemeProvider>
  );
}